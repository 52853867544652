@import 'ant-custom-theme';
@import 'loading';
@import 'utilities';
@import 'variables';
@import 'fonts';
@import 'responsive';

html {
  @include font-family(14, "AvenirNextLTPro-Regular");
}

nz-table {
  width: 100%;
  overflow-x: auto;
}

body {
  overflow-x: hidden;
  background: $grey-color-2 !important;
  @include font-family(14, "AvenirNextLTPro-Regular");
  color: #fff;
}


//scrollbar
* {
  scrollbar-width: thin;
  scrollbar-color: #ffb100 #fff0;
}

*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: none;
}

*::-webkit-scrollbar-thumb {
  background-color: $accent-color;
}

//layout
.private-page-inner {
  border: 1px solid $accent-color !important;
  @include border-radius-dif-val(0, 10, 10, 10);
  background: $grey-color-1 !important;
  padding: 2rem;

  &.has-tabs {
    border-top-left-radius: 0 !important;
  }
}
@media (min-width:2560px){
  .private-page-inner{
    margin-top:-2px;
  }
}
.private-page {
  border: 1px solid $accent-color !important;
  @include border-radius-dif-val(10, 10, 10, 10);
  background: $grey-color-1 !important;
  padding: 2rem;

  &.has-tabs {
    border-top-left-radius: 0 !important;
  }
}


h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: #fff;
  @include font-family(14, "AvenirNextLTPro-Regular");
}

h1 {
  @include font-family(18, "AvenirNextLTPro-Regular");
  text-transform: uppercase;
  padding: 1rem 0 !important;
}

.h-50 {
  height: 50% !important;
}

.h3 {
  text-transform: uppercase;
  @include font-family(16, "AvenirNextLTPro-Regular");

  &.small {
    @include font-family(14, "AvenirNextLTPro-Regular");

    &.lowercase {
      text-transform: lowercase;
    }
  }
}
.m-t-auto{
    margin-top:auto!important;
}
.accent-color {
  color: $accent-color !important;
}

.ant-page-header {
  padding-left: 0 !important;

  .ant-page-header-heading-title {
    @include font-family(16, "AvenirNextLTPro-Regular");
    text-transform: uppercase;
    color: #fff;
  }
}

a {
  color: #fff;

  &:hover,
  &:active,
  &:visited {
    color: $accent-color;
  }

}

h3 {
  b {
    span {
      color: $accent-color !important;
    }
  }
}

//form styles
.ant-input {
  border-radius: 5px !important;
  border: 1px solid #fff !important;
  background: $grey-color-3 !important;
  color: #fff;
  @include font-family(14, "AvenirNextLTPro-Regular");
  padding: 1rem;

  &:hover {
    border: 1px solid $accent-color !important;
  }
}


.ant-input-group-addon {
  display: none;
}

.ant-form-item-has-error {
  background: none !important;
}

//checkbox
.ant-checkbox-wrapper {
  .ant-checkbox {
    .ant-checkbox-inner {
      background-color: unset !important;
    }

    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        border-color: #fff;

        &:after {
          border-color: $accent-color;
        }
      }

    }

    .ant-checkbox-input:focus+.ant-checkbox-inner {
      border-color: $accent-color !important;
    }
  }

  &:hover {
    .ant-checkbox {
      .ant-checkbox-inner {
        border-color: $accent-color !important;
      }
    }
  }

  &:visited {
    .ant-checkbox {
      .ant-checkbox-inner {
        border-color: $accent-color !important;
      }

    }
  }

  &:focus {
    .ant-checkbox {
      .ant-checkbox-inner {
        border-color: $accent-color !important;
      }
    }
  }

  &.ant-checkbox-wrapper-checked {
    &:hover {
      .ant-checkbox-checked {
        &:after {
          border-color: $accent-color !important;
        }
      }
    }
  }

  //input text/email/etc
  input {
    background: none;
    border-color: #fff;
    border-radius: 5px !important;
    border-width: 1px;
    color: #fff;
    padding: .7rem;
    font: 14px "AvenirNextLTPro-Regular", "arial", sans-serif !important;

    &:hover {
      border-color: $accent-color;
      background: none;
    }

    &:focus {
      border-color: $accent-color;
      background: none;
    }

    &:active {
      border-color: $accent-color;
      background: none;
    }

    &:visited {
      border-color: $accent-color;
      background: none;
    }

    &:-webkit-autofill {
      -webkit-text-fill-color: #fff !important;
      -webkit-box-shadow: 0 0 0 30px $grey-color-2 inset !important;
    }

    &:-webkit-autofill:active {
      border-color: $accent-color !important;
      background-color: transparent;
      -webkit-box-shadow: 0 0 0 30px $grey-color-2 inset !important;
      -webkit-text-fill-color: #fff !important;
    }

  }

  .ant-input-disabled,
  .ant-input[disabled] {
    background: none !important;
  }

  //end input

}

.ant-input-group,
.ant-form-item-control-input-content {
  input {
    padding: 1rem !important;

    &:hover {
      border-color: $accent-color !important;
    }
  }
}

//close select menu button
.ant-picker-clear {
  background: $accent-color !important;
  border-radius: 5 !important;

  .anticon-close-circle {
    color: #000 !important;
  }
}

.ant-picker-range .ant-picker-active-bar {
  background: $accent-color !important;
}

.ant-select-selection-item {


  .ant-select-selection-item-content {
    color: #000 !important;
  }

}

.ant-select-selection-item-remove {}

//select manu styles
.ant-select {


  .ant-select-selector {

    background: none !important;
    border: 1px solid #fff !important;
    color: #fff !important;
    border-radius: $border-radius-2;
    padding: .5rem !important;
    height: unset !important;

    &:hover {
      border: 1px solid $accent-color !important;
    }
  }

  .ant-select-arrow {
    svg {
      color: #fff !important;
    }
  }

  &:hover {
    .ant-select-arrow {
      svg {
        color: $accent-color !important;
      }
    }
  }
}


//end select menu styles
//more select
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: $accent-color !important;
}

.ant-select-selector {
  background: none !important;
  border-radius: $border-radius-2 !important;
}

.ant-select-dropdown {
  background-color: #353535 !important;

  .ant-select-item.ant-select-item-option {
    background: none !important;

    &:hover {
      background: $accent-color !important;
    }

    &.ant-select-item-option-active {
      background: $accent-color !important;
      color: black;
    }
  }
}

.ant-select-item {
  color: white;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: $yellow;
}

//end of more select
.forgot-password {
  color: $grey-color-0 !important;
  padding-top: .5rem;

  &:hover {
    color: $accent-color !important;
  }
}

//switch
.trade-switch {
  color: #fff !important;
}

.ant-switch {
  &.ant-switch-checked {
    .ant-switch-inner {
      color: #000 !important;
    }
  }

  background: $red !important;

  svg {
    color: #000 !important;
  }

  .ant-switch-handle {
    &:before {
      background: #000 !important;
    }
  }

  &.ant-switch-checked {
    background: $accent-color !important;

    svg {
      color: #000 !important;
    }

    .ant-switch-handle {
      &:before {
        background: #000 !important;
      }
    }
  }
}

.ant-form-item-required {
  &:before {
    color: $red !important;
  }

  color: #fff !important;
}

.ant-form-item-label {
  label {
    color: #fff !important;
  }
}

label {
  color: #fff !important;
}

.ant-checkbox-wrapper {
  span {
    color: #fff !important;
  }

  .ant-checkbox-input {
    .ant-checkbox-inner {}
  }
}

// input numers
.ant-input-number {
  background: none !important;
  border: 1px solid #fff !important;
  border-radius: $border-radius-2 !important;

  .ant-input-number-input-wrap {
    input {
      color: #fff !important;
      height: unset !important;
    }
  }

  &:hover {
    border: 1px solid $accent-color !important;
  }

  .ant-input-number-handler-wrap {
    background: $accent-color !important;
    border: none !important;

    .ant-input-number-handler {
      border: none !important;

      svg {
        color: #000 !important
      }
    }

    &:hover {
      border: none !important;
      color: #fff !important;
    }
  }
}

.ant-input-number-disabled {
  opacity: .5;

  &:hover {
    border-color: #fff !important;
  }
}

.ant-form-item-control-input {
  .ant-form-item-control-input-content {
    .ant-input-affix-wrapper {
      background: none !important;
      border: none !important;
      padding: .5rem;
      border: 1px solid #fff !important;
      border-radius: $border-radius-2;

      &:hover {
        border-color: $accent-color !important;

        span {
          i {
            color: $accent-color !important;
          }
        }
      }

      input {
        padding: .5rem !important;
        border: none !important;
        background: none !important;
      }

      span {
        margin: 0 .5rem;

        i {
          color: #fff;
        }
      }
    }
  }
}

//picker
.ant-picker {
  background: none !important;
  border: 1px solid #fff !important;
  border-radius: $border-radius-2;
  color: #fff !important;

  input {
    color: #fff !important;
  }

  .ant-picker-suffix {
    color: #fff !important;
  }

  &:hover {
    border: 1px solid $accent-color !important;

    .ant-picker-suffix {
      color: $accent-color !important;
    }
  }

}

//error handling forms
.ant-form-item-has-error .ant-calendar-picker-open .ant-calendar-picker-input,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused,
.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus {
  background: none !important;
}

.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover,
.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: none !important;
}

//end forms styles


.ant-layout {
  background: $grey-color-2 !important;
}

.ant-layout-content {
  border: none !important;
  min-height: auto !important;
}

//side menu
//sider trigger
.ant-layout-sider-trigger {
  background: $grey-color-1 !important;
}

.dashboard-radio {
  height: unset !important;

  .ant-radio-group {
    border: none !important;
    padding: 0 !important;
  }
}

.ant-layout-sider-collapsed {
  .upgrade {
    div.p-x-3 {
      padding: 0 1rem !important;

      button {
        span {
          &:first-child {
            display: inline-block !important;
          }

          &:last-child {
            display: none;
          }
        }
      }
    }
  }
}

.ant-menu-vertical {
  .ant-menu-submenu-title {
    span {
      display: block !important;
    }
  }

  .dashboard-radio {
    height: unset !important;

    .ant-radio-group {
      border: none !important;
      padding: 0 !important;
    }
  }

  padding: 1rem 0 !important;

  div {
    display: flex;
    align-items: center;

    .icon-menu {
      margin-right: 1.5rem;

      svg {
        width: 20px !important;
        height: 20px !important;
        margin-top: 1rem;

        g {
          stroke: rgba(255, 255, 255, 0.65) !important;
        }

        path {
          fill: rgba(255, 255, 255, 0.65) !important;
        }
      }
    }
  }
}

.ant-menu.ant-menu-inline-collapsed {
  .ant-menu-submenu {
    .ant-menu-submenu-title {
      padding: 0.5rem 1rem !important;

      span {
       // display: none !important;
      }
    }
  }

  .ant-menu-item {
    padding: 1rem !important;

    .menu-item-title {
      //display: none !important;
    }

    &.single {


      div .icon-menu svg {
        margin-top: 0 !important;
      }


    }
  }
}

.ant-menu-dark {
  background: none !important;

  .menu-icon {
    margin-right: 10px;
  }
}

.ant-menu-submenu {
  position: relative;
  padding-left: 0;
  z-index: 9;

  &:before {
    position: absolute;
    content: " ";
    width: 100%;
    height: 10px;
    background: $grey-color-1 !important;
    top: 0;
    right: 0;
    border-bottom-right-radius: 10px;
    z-index: 9;
  }

  .ant-menu-submenu-title {
    height: 60px !important;
    margin: 0 !important;
    border-left: 0.5rem solid $grey-color-1 !important;
    user-select: none;
  }

  &.ant-menu-submenu-open {
    background: $grey-color-2 !important;
    position: relative;
    padding-left: 0;
    z-index: 9;

    &:before {
      position: absolute;
      content: " ";
      width: 100%;
      height: 10px;
      background: $grey-color-1 !important;
      top: 0;
      right: 0;
      border-bottom-right-radius: 10px;
      z-index: 99;
    }

    &:after {
      position: absolute;
      content: " ";
      width: 100%;
      height: 10px;
      background: $grey-color-1 !important;
      bottom: 0;
      right: 0;
      border-top-right-radius: 10px;
    }

    .ant-menu-submenu-title {
      border-left: 0.5rem solid $accent-color !important;
    }

    .ant-menu-item {
      padding: 0 0 0 2rem !important;
      height: unset !important;

      div {
        display: flex !important;
        align-items: center;

        .icon-menu {
          margin-right: 1.5rem;

          svg {
            width: 20px !important;
            height: 20px !important;
            margin-top: 1rem !important;

            g {
              stroke: rgba(255, 255, 255, 0.65) !important;
            }

            path {
              fill: rgba(255, 255, 255, 0.65) !important;
            }
          }
        }
      }

      &:hover {
        color: $accent-color;

        .icon-menu {
          svg {
            g {
              stroke: $accent-color !important;
            }

            path {
              fill: $accent-color !important;
            }
          }
        }
      }

      &.ant-menu-item-selected {
        .icon-menu {
          svg {
            g {
              stroke: $accent-color !important;
            }

            path {
              fill: $accent-color !important;
            }
          }
        }
      }
    }
  }

  .ant-menu-sub {
    background: $grey-color-2 !important;
    border-left: 0.5rem solid $accent-color !important;

    .ant-menu-item-selected {
      color: $accent-color;
      background: none !important;

      span {
        color: $accent-color;
      }
    }
  }

  .instance {
    padding-bottom: 2rem !important;

    p {
      padding: 0 0 0.5rem 2.5rem;
    }

    label {
      color: white;
    }
  }
}

.ant-menu-dark {
  .ant-menu-item.single {
    height: 60px !important;
    position: relative !important;
    padding-left: 0 !important;

    div {
      border-left: 0.5rem solid $grey-color-1 !important;
      padding-left: 1rem;
      display: flex;
      align-items: center;

      .icon-menu {
        margin-right: 1.5rem;

        svg {
          width: 20px !important;
          height: 20px !important;
          margin-top: 1rem;

          g {
            stroke: rgba(255, 255, 255, 0.65) !important;
          }

          path {
            fill: rgba(255, 255, 255, 0.65) !important;
          }
        }
      }

      .menu-item-title {
        margin: 0 !important;
        padding: 0 !important;
      }
    }

    &:hover {
      .icon-menu {
        svg {
          g {
            stroke: $accent-color !important;
          }

          path {
            fill: $accent-color !important;
          }
        }
      }

      color: $accent-color;
    }

    &.ant-menu-item-selected {
      background-color: $grey-color-2 !important;
      position: relative !important;
      z-index: 9 !important;

      div {
        border-left: 0.5rem solid $accent-color !important;
        padding-left: 1rem;
        color: $accent-color;

        .icon-menu {
          margin-right: 1.5rem;

          svg {
            width: 20px !important;
            height: 20px !important;
            margin-top: 1rem;

            g {
              stroke: $accent-color !important;
            }

            path {
              fill: $accent-color !important;
            }
          }
        }

        span {
          color: $accent-color;
        }
      }

      &:before {
        position: absolute;
        content: " ";
        width: 100%;
        height: 10px;
        background: $grey-color-1 !important;
        top: 0;
        right: 0;
        border-bottom-right-radius: 10px;
        z-index: 99;
        transform: none !important;
      }

      &:after {
        position: absolute;
        content: " ";
        width: 100%;
        height: 10px;
        background: $grey-color-1 !important;
        top: 50px;
        right: 0;
        border-top-right-radius: 10px;
        z-index: 99;
        opacity: 1 !important;
        transform: none !important;
      }
    }
  }
}


//end side menu styles
//buttons styles
.ant-btn.ant-btn-primary {
  border-color: $accent-color !important;
  background: $accent-color !important;
  color: #000 !important;
  text-transform: uppercase;
  font-size: 14px;
  border-radius: 5px;
  padding: 0.4rem .7rem;
  height: unset;

  &:hover {
    border-color: $accent-color !important;
    background: none !important;
    color: $accent-color !important;
  }

  &:disabled {
    opacity: .5;

    &:hover {
      border-color: $accent-color;
      background: $accent-color;
      color: #000;
    }
  }
}

.ant-btn {
  border-color: $accent-color !important;
  background: none !important;
  color: #fff !important;
  text-transform: uppercase;
  font-size: 14px;
  border-radius: 5px;
  padding: .4rem 0.7rem;
  height: unset;

  &:hover {
    border-color: $accent-color !important;
    background: $accent-color !important;
    color: #000 !important;
  }

  &.ant-btn-link {
    border: none !important;

    &:hover {
      background: none !important;
      color: $accent-color !important;
    }
  }

  &.white {
    background: #fff !important;
    color: #000 !important;
    border-color: #fff !important;
    font-size: 14px !important;
    padding-left: 2rem;
    padding-right: 2rem;

    &:hover {
      background: none !important;
      border-color: $accent-color !important;
      color: #fff !important;
    }

    .red {
      color: $red;
      font-weight: bold;
      margin: 0 .2rem;
    }
  }
}

.ant-btn.ant-btn-primary.ant-btn-dangerous {
  border-color: $red !important;
  background: $red !important;
  color: #fff !important;

  &:hover {
    border-color: $red !important;
    background: none !important;
    color: $red !important;
  }

  &:disabled {
    &:hover {
      border-color: $red;
      background: $red;
      color: #fff;
    }
  }
}

//end buttons styles

.dashboard-radio {
  .ant-radio-wrapper {
    background: $yellow !important;
    border-radius: 5px;
    margin-bottom: .2rem;
    padding: .01rem !important;
    text-transform: uppercase;
    color: #000 !important;
    font-weight: bold;
    font-size: 10px;

    span {
      color: #000 !important;
    }

    &.ant-radio-wrapper-checked {
      background: $accent-color !important;
      color: #000 !important;
    }

    .ant-radio {
      visibility: hidden !important;
      width: 0 !important;
    }
  }
}

//private pages header
.ant-layout-header {
  border: none;

}

//.ant-space {
//  display: flex !important;
//
//  &.center-items {
//    .ant-space-item {
//      background: #fff;
//    }
//  }
//
//  &.live-trading {
//    .ant-space-item {
//      background-color: $accent-color !important;
//    }
//  }
//
//  &.paper-trading {
//    .ant-space-item {
//      background-color: $yellow !important;
//    }
//  }
//
//  .ant-space-item {
//    background: $yellow;
//    border-radius: $border-radius-1;
//    height: unset;
//    padding: 1rem;
//
//    p {
//      line-height: normal;
//      padding: 0 !important;
//      margin: 0 !important;
//      color: #000 !important;
//    }
//  }
//
//  h2 {
//    line-height: normal;
//    margin: 0;
//    background: $yellow;
//    @include border-radius-dif-val(0, 10, 0, 10);
//    padding: 1rem;
//    @include font-family(14, "AvenirNextLTPro-Bold");
//    color: #000;
//  }
//}

//radio buttons styles
.ant-radio-group {
  border: 1px solid $accent-color;
  padding: .5rem;
  border-radius: $border-radius-2;

  label {
    border: none !important;
    border-radius: $border-radius-2 !important;
    text-align: center !important;

    &:before {
      content: none !important;
    }
  }
}

label.ant-radio-button-wrapper {
  background: $grey-color-1 !important;
  border-color: $accent-color !important;
  color: #fff;

  &:before {
    background: $accent-color !important;
  }

  //         &:first-child{
  //             @include border-radius-dif-val(10, 0, 10, 0);
  //         }
  //         &:last-child{
  //             @include border-radius-dif-val(0, 10, 0, 10);
  //         }
  &.ant-radio-button-wrapper-checked {
    background: $accent-color !important;
    color: #000 !important;
    border-color: $accent-color !important;

    &:before {
      background: $accent-color !important;
    }
  }

  &:hover {
    background: $accent-color !important;
    color: #000 !important;
    border-color: $accent-color !important;

    &:before {
      background: $accent-color !important;
    }
  }
}

//card styles

.ant-card {
  border: 1px solid $accent-color;
  border-radius: $border-radius-1;
  padding: 2rem;
  @extend .gradient;

  &.tabCard {
    background: $grey-color-1 !important;
    @include border-radius-dif-val(0, 10, 10, 10);
  }

  &.tabCardBorder {
    background: $grey-color-1 !important;
  }

  &.card-green-gradient {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#233c22+0,233c22+50&0.65+0,0+50 */
    background: -moz-linear-gradient(-45deg, rgba(35, 60, 34, 0.65) 0%, rgba(35, 60, 34, 0) 50%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, rgba(35, 60, 34, 0.65) 0%, rgba(35, 60, 34, 0) 50%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, rgba(35, 60, 34, 0.65) 0%, rgba(35, 60, 34, 0) 50%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6233c22', endColorstr='#00233c22', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
  }

  &.card-redish-gradient {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#3f2626+0,3f2626+50&0.65+0,0+50 */
    background: -moz-linear-gradient(-45deg, rgba(63, 38, 38, 0.65) 0%, rgba(63, 38, 38, 0) 50%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, rgba(63, 38, 38, 0.65) 0%, rgba(63, 38, 38, 0) 50%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, rgba(63, 38, 38, 0.65) 0%, rgba(63, 38, 38, 0) 50%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a63f2626', endColorstr='#003f2626', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */

  }

  .ant-card-extra {
    float: none !important;
    margin: 0 !important;
  }

  .ant-card-head {
    border: none !important;
    padding: 0 !important;

    .ant-card-head-title {
      color: #fff;
      @include font-family(16, "AvenirNextLTPro-regular");
      text-transform: uppercase;
    }
  }

  .ant-card-body {
    padding: 0 !important;

    .ant-table {
      background: none !important;

      .ant-table-row {
        .ant-table-cell {
          background: none !important;
          color: #fff;

        }
      }
    }
  }
}
@media (min-width:2560px){
  .ant-card {  
    &.tabCard {
      margin-top:-3px;
    }
  }
}
.simple-card {
  border: none !important;
  background: none !important;
}

//
.summary {
  .ant-tabs {}
}


//table styles
.ant-table {
  background: none !important;

  .ant-table-thead {
    .ant-table-row {
      .ant-table-cell {
        background: #292929 !important;
        color: #fff;
        @include font-family(14, "AvenirNextLTPro-Bold");

        &:first-child {
          @include border-radius-dif-val(10, 0, 0, 0)
        }

        &:last-child {
          @include border-radius-dif-val(0, 10, 0, 0)
        }
      }
    }
  }

  .ant-table-content {
    .ant-table-row {
      .ant-table-cell {
        color: #fff;
        text-align: center;
        border: none !important;

        &:first-child {
          text-align: left !important;
        }

        a {
          color: $accent-color;
        }

        .anticon {
          &:hover {
            color: $accent-color !important;
          }

          &.anticon-check {
            svg {
              color: $green !important;
            }
          }
        }
      }

      &:hover {
        background: -moz-radial-gradient(center, ellipse cover, rgba(33, 48, 48, 0.65) 0%, rgba(33, 48, 48, 0) 50%, rgba(33, 48, 48, 0) 97%);
        /* FF3.6-15 */
        background: -webkit-radial-gradient(center, ellipse cover, rgba(33, 48, 48, 0.65) 0%, rgba(33, 48, 48, 0) 50%, rgba(33, 48, 48, 0) 97%);
        /* Chrome10-25,Safari5.1-6 */
        background: radial-gradient(ellipse at center, rgba(33, 48, 48, 0.65) 0%, rgba(33, 48, 48, 0) 50%, rgba(33, 48, 48, 0) 97%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6213030', endColorstr='#00213030', GradientType=1);
        /* IE6-9 fallback on horizontal gradient */
        background-size: 120% auto;
        background-repeat: no-repeat;
        background-position: center;

        .ant-table-cell {
          background: none;

        }
      }
    }
  }

  .ant-table-footer,
  .ant-table-thead>tr>th {
    background: none !important;
    border-top: 1px solid $accent-color !important;
    color: #fff !important;

    span {
      &.ant-table-column-title {
        color: #fff !important;
      }
    }
  }
}

//settings table
.table-settings {
  .ant-table {
    .ant-table-content {
      .ant-table-row {
        .ant-table-cell {
          padding: 2rem .5rem !important;

          .anticon.anticon-check svg {
            color: #000 !important;
            opacity: 1 !important;
          }

          &:last-child {
            text-align: right !important;
          }

        }
      }
    }
  }
}

.nz-disable-td.ant-table-cell {
  background: none !important;
  border: none !important;
}

//end table styles


//tabs

.ant-tabs {
  .ant-tabs-content-holder {
    position: relative;
    z-index: 9;
  }

  .ant-tabs-nav {
    margin: 0 0 0 0 !important;
    position: relative;
    z-index: 99;
    top: 1px;

    &:before {
      background: none;
      border: none;
    }

    .ant-tabs-nav-list {
      .ant-tabs-tab {
        text-transform: uppercase;
        color: #fff;

        .ant-tabs-tab-btn {
          padding: 1rem 2rem !important;

        }

        &.ant-tabs-tab-active {
          @include border-radius-dif-val(10, 10, 0, 0);
          border: 1px solid $accent-color;
          border-bottom: none !important;
          background: $grey-color-1 !important;

          .ant-tabs-tab-btn {
            color: #fff !important;
          }
        }

      }

      .ant-tabs-ink-bar {
        display: none !important;
      }
    }

  }

  .ant-tabs-content-holder {
    .ant-tabs-content-top {
      height: 100% !important;
    }
  }
}

//table pagination
.ant-table-pagination {
  .ant-pagination-item {
    border: 1px solid #fff !important;
    background: none !important;
    color: #fff !important;
    border-radius: $border-radius-2 !important;

    a {
      color: #fff !important;
    }

    &:hover {
      border: 1px solid $accent-color !important;

      a {
        color: #fff !important;
      }
    }

    &.ant-pagination-item-active {
      background: $accent-color !important;
      border: 1px solid $accent-color !important;

      a {
        color: #000 !important;
      }
    }

  }

  .ant-pagination-prev,
  .ant-pagination-next {
    &.ant-pagination-disabled {
      opacity: .5;

      button {
        background: none !important;
        border: 1px solid $accent-color !important;
        color: #fff !important;
        border-radius: $border-radius-2 !important;

        svg {
          color: #fff !important;
        }

        &:hover {
          background: $accent-color !important;

          svg {
            color: #000 !important;
          }
        }
      }
    }
  }
}

//spin loader
.ant-spin-blur {
  border-radius: $border-radius-1 !important;
  padding: 2rem;
}

.ant-spin-dot-item {
  background: $accent-color !important;
}

//slider
.ant-slider {
  .ant-slider-handle {
    border-color: $accent-color !important;
  }

  .ant-slider-track {
    background: $accent-color !important;
  }

  &:hover {
    .ant-slider-handle:not(.ant-tooltip-open) {
      border-color: $accent-color !important;
    }
  }
}

//popover

.ant-popover-inner {
  background: $grey-color-1 !important;
  border: 1px solid $accent-color !important;
  border-radius: $border-radius-1 !important;
  padding: 2rem !important;

  .ant-popover-message-title {
    color: #fff !important;
    margin-bottom: 2rem;
  }
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

//responsive global
@media (max-width: 1600px) {
    .ant-divider-vertical{
        margin:0 5px!important;
    }
  .ant-space.center-items .ant-space-item:last-child {
    border-radius: 10px !important;
  }

  .ant-space h2 {
    border-radius: 10px !important;
  }
}

.ant-select-selection-search {
  left: 8px !important;
}

.ant-select-selection-search-input {
  height: 100% !important;
}

.ant-select-selection-placeholder {
  padding-left: 1rem !important;
}

@media (max-width: 576px) {
        .ant-divider-vertical{
            display:none;
        }
        .ant-tabs {
          .ant-tabs-nav {
            .ant-tabs-nav-list {
              .ant-tabs-tab {    
                .ant-tabs-tab-btn {
                  padding: 0rem 1rem !important;
                }
              }
            }
          }
        }
    .ant-select-selection-placeholder  {
         white-space: normal!important;
    }
      .ant-table-pagination-right{
            justify-content: start!important;
        }
        .ant-card{
            padding:0rem;
            border:none;
            background:none;
            border-bottom:1px solid $accent-color;
            border-radius:0;
            padding-bottom:1rem;
            &.card-green-gradient {
                background:none;
                border-radius:0;
            }
            &.card-redish-gradient{
                background:none;
            }
            &.tabCard{
                border-radius:0!important;
                border-bottom:0;
            }
        }
        .private-page-inner{
            padding:1rem;
        }
        // .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list, .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list{
        //     border-bottom:1px solid $accent-color;;
        // }
        .take-profit-card, .stop-loss-card, .buySell {
          .ant-card-head-wrapper{
            flex-direction:column;
            align-items: flex-start;
          }
        }
        
    }
    .anticon-info-circle{
        margin-left:0.5rem!important;
        display:inline-block;
    }
    @media (max-width: 1400px) {
        .ant-divider-vertical{
            margin:0 3px!important;
        }
}

@media (max-width: 767px) {
  .desktop-only {
      display: none;
  }
}
.ant-dropdown-menu {
  background-color: #1b1c1c !important;
}
.ant-input-disabled, .ant-input[disabled] {
  color:white !important;
  opacity:0.8;
}