//1
@font-face {
  font-family:AvenirNextLTPro-Bold;
  src: url(/assets/fonts/AvenirNextLTPro-Bold.otf);
}
//2
@font-face {
  font-family:AvenirNextLTPro-BoldCn;
  src: url(/assets/fonts/AvenirNextLTPro-BoldCn.otf);
}
//3
@font-face {
  font-family:AvenirNextLTPro-BoldCnIt;
  src: url(/assets/fonts/AvenirNextLTPro-BoldCnIt.otf);
}
//4
@font-face {
  font-family:AvenirNextLTPro-BoldIt;
  src: url(/assets/fonts/AvenirNextLTPro-BoldIt.otf);
}
//5
@font-face {
  font-family:AvenirNextLTPro-Cn;
  src: url(/assets/fonts/AvenirNextLTPro-Cn.otf);
}
//6
@font-face {
  font-family:AvenirNextLTPro-CnIt;
  src: url(/assets/fonts/AvenirNextLTPro-CnIt.otf);
}
//7
@font-face {
  font-family:AvenirNextLTPro-Demi;
  src: url(/assets/fonts/AvenirNextLTPro-Demi.otf);
}
//8
@font-face {
  font-family:AvenirNextLTPro-DemiCn;
  src: url(/assets/fonts/AvenirNextLTPro-DemiCn.otf);
}
//9
@font-face {
  font-family:AvenirNextLTPro-DemiCnIt;
  src: url(/assets/fonts/AvenirNextLTPro-DemiCnIt.otf);
}
//10
@font-face {
  font-family:AvenirNextLTPro-DemiIt;
  src: url(/assets/fonts/AvenirNextLTPro-DemiIt.otf);
}
//11
@font-face {
  font-family:AvenirNextLTPro-Heavy;
  src: url(/assets/fonts/AvenirNextLTPro-Heavy.otf);
}
//12
@font-face {
  font-family:AvenirNextLTPro-HeavyCn;
  src: url(/assets/fonts/AvenirNextLTPro-HeavyCn.otf);
}
//13
@font-face {
  font-family:AvenirNextLTPro-HeavyCnIt;
  src: url(/assets/fonts/AvenirNextLTPro-HeavyCnIt.otf);
}
//14
@font-face {
  font-family:AvenirNextLTPro-HeavyIt;
  src: url(/assets/fonts/AvenirNextLTPro-HeavyIt.otf);
}
//15
@font-face {
  font-family:AvenirNextLTPro-It;
  src: url(/assets/fonts/AvenirNextLTPro-It.otf);
}
//16
@font-face {
  font-family:AvenirNextLTPro-Medium;
  src: url(/assets/fonts/AvenirNextLTPro-Medium.otf);
}
//17
@font-face {
  font-family:AvenirNextLTPro-MediumCn;
  src: url(/assets/fonts/AvenirNextLTPro-MediumCn.otf);
}
//18
@font-face {
  font-family:AvenirNextLTPro-MediumCnIt;
  src: url(/assets/fonts/AvenirNextLTPro-MediumCnIt.otf);
}
//19
@font-face {
  font-family:AvenirNextLTPro-MediumIt;
  src: url(/assets/fonts/AvenirNextLTPro-MediumIt.otf);
}
//20
@font-face {
  font-family:AvenirNextLTPro-Regular;
  src: url(/assets/fonts/AvenirNextLTPro-Regular.otf);
}
//21
@font-face {
  font-family:AvenirNextLTPro-UltLt;
  src: url(/assets/fonts/AvenirNextLTPro-UltLt.otf);
}
//22
@font-face {
  font-family:AvenirNextLTPro-UltLtCn;
  src: url(/assets/fonts/AvenirNextLTPro-UltLtCn.otf);
}
//23
@font-face {
  font-family:AvenirNextLTPro-UltLtCnIt;
  src: url(/assets/fonts/AvenirNextLTPro-UltLtCnIt.otf);
}
//24
@font-face {
  font-family:AvenirNextLTPro-UltLtIt;
  src: url(/assets/fonts/AvenirNextLTPro-UltLtIt.otf);
}