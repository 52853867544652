$header-height: 10rem;
$border-radius-1:10px;
$border-radius-2:5px;


//greys
$grey-color-0:#AFAFAF;
$grey-color-1:#1D1E1E;
$grey-color-2: #353535;
$grey-color-3: #272727;

//colors
$accent-color :#00FFFF;
$yellow:#F4FB73;
$red:#E35E5D;
$green:#74C236;
$purple:#A374E7;


//boxes gradient
.gradient{
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#223333+0,223333+49,000000+100&0.65+0,0+50,0+100 */
    background: -moz-linear-gradient(-45deg,  rgba(34,51,51,0.65) 0%, rgba(34,51,51,0.01) 49%, rgba(33,50,50,0) 50%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg,  rgba(34,51,51,0.65) 0%,rgba(34,51,51,0.01) 49%,rgba(33,50,50,0) 50%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg,  rgba(34,51,51,0.65) 0%,rgba(34,51,51,0.01) 49%,rgba(33,50,50,0) 50%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6223333', endColorstr='#00000000',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
//font size/family
@mixin font-family($font-size, $font-family){
    font: $font-size + px $font-family, "arial", sans-serif;
}
//border radius different angles
@mixin border-radius-dif-val($top-left, $top-right, $bottom-left, $bottom-right){
    border-top-left-radius: $top-left + px!important;
    border-top-right-radius: $top-right + px!important;
    border-bottom-left-radius: $bottom-left + px!important;
    border-bottom-right-radius: $bottom-right + px!important;
}