@import "~ng-zorro-antd/ng-zorro-antd.min.css";
@import "variables";

$primary-color: #6AEF9E;

.ant-layout {
  background: #ffffff;
}

.ant-menu-item {
  &:active {
    outline: none !important;
  }
}



.ant-empty-image {
  height: 75px;
}

.formFieldHint {
  font-size: 90%;
  margin-top: 0.5em;
  color: #7b7b7b;
}

.info-box {
  border: 2px solid #f1f1f1;
  border-radius: 4px;
  padding: 2em;

  h3 {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 1.5em;
  }
}

.danger {
  color: #ff5548 !important;
}

.ant-modal-content {
  background-color: #000;
}

.ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-header {
  color: #fff;
  background: #000;
  border-bottom-color: #00FFFF;
}

.ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-title {
  color: #fff;
  background: #000;
  border-bottom-color: #00FFFF;
}

.ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content {
  color: #fff;
  background: #000;
  border-bottom-color: #00FFFF;
}

.ant-modal .ant-modal-content .ant-modal-close {
  color: #fff;
  background: #000;
  border-bottom-color: #00FFFF;
}

.ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-footer {
  color: #fff;
  background: #000;
  border-bottom-color: #00FFFF;
}

.ant-modal-header {
  padding: 17px 24px;
  color: #fff;
  background: #000;
  border-bottom-color: #00FFFF;
}

.ant-modal-title {
  color: #fff;
}

.ant-modal-footer {
  border-top-color: #00FFFF;
}
