//responsive styles




//extrextra large
 @media (max-width: 1600px) {
    //margin top
    .m-t-xxl-2{
        margin-top:2em!important;
    }
    //margin bottom
    .m-b-xxl-1{
        margin-bottom:1em!important;
    }
   .m-b-xxl-2{
        margin-bottom:2em!important;
    }
    //margin left
    .m-l-xxl-1{
        margin-left:1em!important;
    }
    //margin-right
    .m-r-xxl-1{
        margin-right:1em!important;
    }
    .m-r-xxl-2{
        margin-right:2em!important;
    }
    //padding right
    .p-r-xxl-0{
        padding-right:0em!important;
    }
    //padding-left
    .p-l-xxl-2{
        padding-left:2em!important;
    }
    .p-l-xxl-0{
    padding-left:0em!important;
    }
  }

// Extra large devices (large desktops, 1200px and up)
@media (max-width: 1200px) {
    //margin top
    .m-t-xl-2{
        margin-top:2em!important;
    }
    //margin bottom
   .m-b-xl-2{
             margin-bottom:2em!important;
         }
    //margin left
   .m-l-xl-0{
        margin-left:0em!important;
    }
        .m-l-xl-0{
            margin-left:0!important;
        }
    //margin right
    .m-r-xl-2{
        margin-right:2em!important;
    }
    .m-r-xl-0{
        margin-right:0em!important;
    }
    //padding left
    .p-l-xl-1{
        padding-left:1em!important;
    }
    .p-l-xl-2{
        padding-left:2em!important;
    }
    .p-l-xl-0{
        padding-left:0!important;
    }
    //padding right
    .p-r-xl-1{
        padding-right:1em!important;
    }
    .p-r-xl-0{
        padding-right:0!important;
    }
        .order-xl-2{
        order:2!important;
        }
 }

// Large devices (desktops, 992px and up)
@media (max-width: 992px) {
    .m-r-lg-0{
        margin-right:0!important;
    }
    .m-l-lg-0{
        margin-left:0!important;
    }

 }
// Medium devices (tablets, 768px and up)
@media (max-width: 768px) {
    .m-r-md-0{
        margin-right:0!important;
    }
    .m-t-md-2{
        margin-top:2em!important;
    }
}
// Small devices (landscape phones, 576px and up)
@media (max-width: 576px) {
    //

 }






