// css utilities stylesheet
// Utility classes
.wrapper {
  max-width: 1366px;
  margin: 0 auto;
}

.mx-auto {
  margin: 0 auto;
}

.blackBg {
  background: black;
}
.w-20{
    width:20%!important;
}
.w-16{
    width:16.66%!important;
}
.w-33{
    width:33.33%!important;
}
.w-50{
    width:50%!important;
}
//margins
.m-0 {
  margin: 0 !important;
}
.m-r-auto{
    margin-right:auto!important;
}
.m-r-1{
    margin-right:1em!important;
}
.m-r-2{
    margin-right:2em!important;
}
.m-t-1-5{
      margin-top: .5em !important;
}
.m-t-1 {
  margin-top: 1em !important;
}

.m-t-2 {
  margin-top: 2em !important;
}

.m-t-3 {
  margin-top: 3em !important;
}

.m-t-auto {
  margin-top: auto;
}

.m-l-1 {
  margin-left: 1em !important;
}

.m-l-2 {
  margin-left: 2em !important;
}

.m-l-3 {
  margin-left: 3em !important;
}

//OY
.m-y-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.m-y-1 {
  margin: 1em 0;
}

.m-y-2 {
  margin: 2em 0;
}

.m-y-3 {
  margin: 3em 0;
}

.m-y-4 {
  margin: 4em 0 !important;
}

.m-y-5 {
  margin: 5em 0;
}

//OX
.m-x-auto {
  margin: 0 auto !important;
}
.m-l-auto{
    margin-left:auto!important;
}
.m-x-1 {
  margin: 0 1em;
}

.m-x-2 {
  margin: 0 2em;
}

.m-x-3 {
  margin: 0 3em;
}

.m-x-4 {
  margin: 0 4em;
}

.m-x-5 {
  margin: 0 5em;
}

.m-t-5 {
  margin-top: 5em !important;
}

.m-b-0 {
  margin-bottom: 0 !important;
}

.m-b-1 {
  margin-bottom: 1em !important;
}

.m-b-2 {
  margin-bottom: 2em !important;
}

.m-b-3 {
  margin-bottom: 3em !important;
}

.m-b-4 {
  margin-bottom: 4em !important;
}

.m-b-5 {
  margin-bottom: 5em !important;
}

// padding
.p-r-0 {
  padding-right: 0!important;
}
.p-r-1 {
  padding-right: 1em !important;
}
.p-r-2 {
  padding-right: 2em !important;
}
.p-r-3 {
  padding-right: 3em !important;
}
.p-r-4 {
  padding-right: 4em !important;
}

//OY
.p-y-1 {
  padding: 1em 0;
}

.p-y-2 {
  padding: 2em 0;
}

.p-y-3 {
  padding: 3em 0;
}

.p-y-4 {
  padding: 4em 0 !important;
}

.p-y-5 {
  padding: 5em 0 !important;
}

//OX
.p-x-0 {
    padding-left: 0!important;
    padding-right: 0!important;
}
.p-x-1 {
  padding: 0 1em;
}

.p-x-2 {
  padding: 0 2em;
}

.p-x-3 {
  padding: 0 3em !important;
}

.p-x-4 {
  padding: 0 3em;
}

.p-x-5 {
  padding: 0 5em !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 1em;
}

.p-2 {
  padding: 2em;
}

.p-3 {
  padding: 3em !important;
}

.p-4 {
  padding: 4em !important;
}

.p-5 {
  padding: 5em !important;
}

.p-l-0 {
  padding-left: 0 !important;
}
.p-l-1 {
  padding-left: 1em !important;
}
.p-l-2 {
  padding-left: 2em !important;
}
.p-l-3 {
  padding-left: 3em !important;
}
.p-l-4 {
  padding-left: 4em !important;
}
.p-l-5 {
  padding-left: 5em !important;
}

.p-t-1 {
  padding-top: 1em !important;
}

.p-t-2 {
  padding-top: 2em !important;
}

.p-t-3 {
  padding-top: 3em !important;
}

.p-b-4 {
  padding-bottom: 4em !important;
}

.p-b-5 {
  padding-bottom: 5em !important;
}
.p-b-0 {
  padding-bottom: 0 !important;
}
//align self
.align-self-center {
  align-self: center;
}

.align-self-start {
  align-self: start;
}

.align-self-baseline {
  align-self: flex-end;
}

// width
.w-100 {
  width: 100%;
}

// height
.h-100 {
  height: 100% !important;
}
.h-50{
    height: 50%!important;
}

.h-unset {
  height: unset !important;
}

// floats
.float-none {
  float: none !important;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

//display
.d-block {
  display: block !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-block {
  display: inline-block !important;
}

.row-eq-height {
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

//buttons
.btn-green {
  background: #6AEF9E !important;
  border-radius: 30px !important;
  height: unset !important;
  font-family: 'SpaceGrotesk-Regular';
}

.btn-purple {
  background: #7737FE !important;
  border-radius: 30px !important;
  color: #fff !important;
  height: unset !important;
}

.btn-grey {
  border-radius: 30px !important;
  height: unset !important;
  font-family: 'SpaceGrotesk-Regular';
  background: rgba(59, 115, 255, 0.1) !important;
  color: #3B73FF !important;
  border: none !important
}
.border-left {
  border-left: 1px solid #D9D9D9;
}
/* ==========================================================================
Align
========================================================================== */
.text-right {
  text-align: right;
}
.text-center {
  text-align: center !important;
}

.text-end {
  text-align: end !important;
}

.text-start {
  text-align: start !important;
}
.text-left{
    text-align:left!important;
}
.justify-end{
    justify-content: end!important;
}
.align-center{
    align-items:center!important;
}
.align-self-end{
        align-self: end!important;
}
/* ==========================================================================
Text selection
========================================================================== */
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome and Opera */
}
/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}
